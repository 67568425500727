import { DocumentStatus } from '@kanbu/schema/enums';
import throttle from 'lodash.throttle';

import { trpc, trpcUtils } from '@/services/trpc';

import type { FileUploadAdapter } from '../fileUploadTypes';

/**
 * Throttle invalidation to avoid too many requests when file uploads finish.
 */
const throttledInvalidate = throttle(
  () => trpcUtils.documents.findAll.invalidate(),
  3000,
);

export const createDocumentUploadAdapter = (
  chatId: string,
  onSuccess?: () => void,
): FileUploadAdapter => {
  const createMutation = trpc.documents.createDocument.useMutation();
  const updateMutation = trpc.documents.update.useMutation();

  return {
    upload: async (file: File): Promise<void> => {
      // Get signed URL from backend
      const response = await createMutation.mutateAsync({
        fileName: file.name,
        contentType: file.type,
        size: file.size,
        chatId,
      });

      // Upload file to GCP
      const uploadResponse = await fetch(response.uploadUrl, {
        method: 'PUT',
        body: file,
        mode: 'cors',
        headers: {
          'Content-Type': file.type,
        },
      });

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload file to storage');
      }

      await updateMutation.mutateAsync({
        id: response.document.id,
        status: DocumentStatus.Ready,
      });

      throttledInvalidate();
      onSuccess?.();
    },
  };
};
