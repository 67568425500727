import { Button, cn, Dialog, Input as UIInput } from '@utima/ui';
import { Checkbox, Input, type TypedFormState } from '@utima/ui-informed';
import { Scope, type FormApi } from 'informed';
import { Globe, ListChecks, ListTodo, Search } from 'lucide-react';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicForm } from '@/components/form/BasicForm';
import { trpc } from '@/services/trpc';

export type WebsiteFormProps = {
  chatId: string;
};

type WebsiteFormValues = {
  uri: string;
  urls: boolean[];
};

function isUrlMatch(url: string, search: string) {
  return url.toLowerCase().includes(search.toLowerCase());
}

export const AddWebsites = memo(function WebsiteForm({
  chatId,
}: WebsiteFormProps) {
  const { t } = useTranslation(['glossary', 'documents']);
  const [search, setSearch] = useState('');
  const [selectedCount, setSelectedCount] = useState(0);
  const formApi = useRef<FormApi>(null);
  const utils = trpc.useUtils();

  const {
    mutateAsync: mapUrl,
    isPending,
    data,
    reset: resetMapUrl,
  } = trpc.documents.mapUrl.useMutation();
  const { mutateAsync: createWebsite, isPending: isCreatingWebsite } =
    trpc.documents.createWebsite.useMutation({
      onSuccess: async () =>
        Promise.all([
          utils.documents.findAll.invalidate(),
          utils.chats.findOne.invalidate({ id: chatId }),
        ]),
    });

  const handleCrawl = useCallback(async () => {
    const url = formApi.current?.getValue('uri') as string;

    if (!url) {
      return;
    }

    const response = await mapUrl({ url });
    setSelectedCount(response?.length ?? 0);
  }, [mapUrl]);

  const handleAdd = useCallback(() => {
    formApi.current?.submitForm();
  }, []);

  const handleSubmit = useCallback(
    async ({ values }: TypedFormState<WebsiteFormValues>) => {
      let finalUrls: string[] = [];

      // Handle multiple URLs input from the list
      if (Array.isArray(values.urls) && values.urls.length) {
        const urls = data?.filter((_, index) => values.urls[index]);

        // Filter out empty strings and invalid URLs
        const filteredUrls = (urls ?? [])?.map(url => {
          try {
            return new URL(url).toString();
          } catch {
            return null;
          }
        });

        // Remove duplicates
        finalUrls = Array.from(
          new Set(filteredUrls.filter(Boolean)),
        ) as string[];
      } else {
        // Handle only single URL input
        finalUrls = [values.uri];
      }

      await createWebsite({ urls: finalUrls, chat: chatId });
      formApi.current?.reset();
      resetMapUrl();
    },
    [createWebsite, data, chatId, resetMapUrl],
  );

  return (
    <>
      <BasicForm
        onSubmit={handleSubmit}
        formApiRef={formApi}
        className='h-full min-h-0 grow space-y-4'
      >
        <div className='flex flex-row items-end gap-2'>
          <Input
            name='uri'
            label={t('glossary:labels.url')}
            placeholder='https://www.yourwebsite.com'
            required
          />
          <Button
            size='sm'
            className='whitespace-nowrap'
            disabled={isPending}
            loading={isPending}
            onClick={handleCrawl}
            icon={<Globe className='size-4' />}
          >
            {t('glossary:actions.crawlWebsite')}
          </Button>
        </div>

        {data && data.length > 0 && (
          <Scope scope='urls'>
            <div className='flex max-h-[calc(100%-80px)] min-h-0 grow flex-col space-y-2 overflow-hidden rounded-radius border border-border bg-gray-50 p-4'>
              <div className='flex-none text-sm font-bold text-gray-600'>
                {t('documents:texts.selectWebSources')}
              </div>
              <div className='flex-none'>
                <UIInput
                  name='search'
                  placeholder={t('glossary:actions.filter')}
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  addonBefore={<Search className='size-4' />}
                />
              </div>

              <div className='min-h-0 grow overflow-hidden overflow-y-auto rounded-lg border border-border bg-white'>
                <div className='size-full p-4 text-xs [&>*]:font-bold'>
                  {data.map((url, index) => (
                    <Checkbox
                      key={url}
                      size='sm'
                      defaultValue
                      name={`${index}`}
                      description={url}
                      onCheckedChange={value => {
                        setSelectedCount(v => (value ? v + 1 : v - 1));
                      }}
                      className={cn(
                        '[&>*]:py-1.5',
                        !isUrlMatch(url, search) && 'hidden',
                      )}
                    />
                  ))}
                </div>
              </div>

              <div className='mt-2 flex items-start justify-between'>
                <div className='flex items-center gap-2'>
                  <Button
                    outline
                    variant='ghost'
                    size='xs'
                    onClick={() => {
                      let count = 0;

                      data.forEach((url, index) => {
                        if (!isUrlMatch(url, search)) {
                          return;
                        }

                        count++;
                        formApi.current?.setValue(`urls.${index}`, true);
                      });

                      setSelectedCount(count);
                    }}
                  >
                    <ListChecks className='size-4' />
                    {t('glossary:actions.selectAll')}
                  </Button>
                  <Button
                    outline
                    variant='ghost'
                    size='xs'
                    onClick={() => {
                      let count = 0;

                      data.forEach((url, index) => {
                        if (!isUrlMatch(url, search)) {
                          return;
                        }

                        count++;
                        formApi.current?.setValue(`urls.${index}`, false);
                      });

                      setSelectedCount(count);
                    }}
                  >
                    <ListTodo className='size-4' />
                    {t('glossary:actions.deselectAll')}
                  </Button>
                </div>
                <div className='text-sm font-bold text-gray-600'>
                  {selectedCount}/{data.length}
                </div>
              </div>
            </div>
          </Scope>
        )}
      </BasicForm>

      <Dialog.Footer>
        <Button
          disabled={isCreatingWebsite}
          loading={isCreatingWebsite}
          onClick={handleAdd}
        >
          {t('glossary:actions.add')}
        </Button>
        <Dialog.Close asChild>
          <Button variant='secondary'>{t('glossary:actions.close')}</Button>
        </Dialog.Close>
      </Dialog.Footer>
    </>
  );
});
