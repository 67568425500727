import type { DocumentTypeMetadata, DocumentType } from '@kanbu/schema';
import { ContentType, DocumentStatus } from '@kanbu/schema/enums';
import { createColumnHelper } from '@tanstack/react-table';
import { Badge, Button, cn, Dialog, Tooltip } from '@utima/ui';
import {
  Globe,
  Link,
  RefreshCcw,
  FileText,
  FlaskConical,
  FileSymlink,
} from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsCell } from '@/components/dataTable/cells/ActionsCell';
import { IconWrapper } from '@/components/iconWrapper/IconWrapper';

import { useDocumentMutation } from './useDocumentMutation';
import { GenerateTestCasesDialog } from '../components/GenerateTestCasesDialog';

const columnHelper = createColumnHelper<DocumentType>();

export function useDocumentCols() {
  const { t } = useTranslation(['glossary', 'enums']);
  const {
    remove: { mutateAsync: remove },
    update: { mutateAsync: update },
  } = useDocumentMutation();

  return useMemo(
    () => [
      columnHelper.accessor(row => row.name, {
        id: 'name',
        header: t('glossary:labels.name'),
        meta: {
          sortable: 'name',
        },
        cell: ({ row }) => {
          const data = row.original;

          return (
            <div className='flex items-center gap-3'>
              <IconWrapper
                size='sm'
                className={cn(
                  data.type === ContentType.Document && 'text-danger',
                  data.type === ContentType.Website && 'text-primary',
                  data.type === ContentType.ExternalDocument &&
                    'text-amber-500',
                )}
                Icon={
                  data.type === ContentType.Document
                    ? FileText
                    : data.type === ContentType.Website
                      ? Globe
                      : FileSymlink
                }
              />
              <div className='flex flex-col gap-0.5'>
                <p className='break-all font-medium leading-tight'>
                  {data.name}
                </p>
                {data.status === DocumentStatus.Error && data.metadata.error ? (
                  <span className='text-xs text-danger'>
                    {data.metadata.error}
                  </span>
                ) : (
                  data.type === ContentType.Document && (
                    <p className='text-xs text-gray-600'>
                      {data.metadata?.size
                        ? (
                            (data.metadata as DocumentTypeMetadata)?.size /
                            1024 /
                            1024
                          ).toFixed(2) + ' MB'
                        : ''}
                    </p>
                  )
                )}
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.status, {
        id: 'status',
        header: t('glossary:labels.status'),
        meta: {
          sortable: 'status',
        },
        cell: ({ row }) => (
          <Badge
            size='sm'
            variant={
              row.original.status === DocumentStatus.Error
                ? 'danger'
                : row.original.status === DocumentStatus.Done
                  ? 'success'
                  : 'info'
            }
            outline
          >
            {t(`enums:documentStatus.${row.original.status}`)}
          </Badge>
        ),
      }),
      columnHelper.accessor(row => row.embeddingsCount, {
        id: 'embeddingsCount',
        header: t('glossary:labels.embeddingsCount'),
        meta: {
          sortable: 'embeddingsCount',
          align: 'right',
        },
        cell: ({ row }) => Number(row.original.embeddingsCount) || '-',
      }),
      columnHelper.display({
        id: 'actions',
        header: t('glossary:labels.actions'),
        cell: ({ row }) => (
          <ActionsCell onDelete={() => remove({ id: row.original.id })}>
            <Tooltip title={t('glossary:actions.refresh')}>
              <Button
                variant='ghost'
                className='text-green-500'
                outline
                size='icon-xs'
                onClick={() =>
                  update({
                    id: row.original.id,
                    status: DocumentStatus.Ready,
                  })
                }
              >
                <RefreshCcw className='size-4' />
              </Button>
            </Tooltip>
            <Button asChild variant='ghost' outline size='icon-xs'>
              <a
                href={
                  row.original.type === ContentType.Document
                    ? (row.original.metadata as DocumentTypeMetadata).publicUrl
                    : row.original.uri
                }
                target='_blank'
                rel='noreferrer'
              >
                <Link className='size-4' />
              </a>
            </Button>
            <Dialog.Root>
              <Tooltip title={t('glossary:actions.generateTestCases')}>
                <Dialog.Trigger asChild>
                  <Button variant='ghost' outline size='icon-xs'>
                    <FlaskConical className='size-4' />
                  </Button>
                </Dialog.Trigger>
              </Tooltip>
              <GenerateTestCasesDialog document={row.original} />
            </Dialog.Root>
          </ActionsCell>
        ),
        meta: {
          align: 'center',
        },
      }),
    ],
    [remove, update, t],
  );
}
