import type { DocumentType } from '@kanbu/schema';
import type { PopulateParams } from '@kanbu/shared';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TablePage } from '@/components/page/TablePage';
import { DocumentsStatusSummary } from '@/features/chats';
import {
  AddDocumentsModal,
  DocumentContentModal,
  useDocumentCols,
} from '@/features/documents';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';

const populate: PopulateParams = [
  'documentsCount',
  'inProgressDocumentsCount',
  'erroredDocumentsCount',
  'doneDocumentsCount',
];

export const Route = createFileRoute('/_baseLayout/chats/$chatId/documents/')({
  component: DocumentsPage,
  loader: async ({ context: { trpcUtils }, params: { chatId } }) =>
    trpcUtils.chats.findOne.ensureData({ id: chatId, populate }),
});

export function DocumentsPage() {
  const { chatId } = Route.useParams();
  const { t } = useTranslation(['globals', 'documents', 'chats']);
  const columns = useDocumentCols();
  const [data] = trpc.chats.findOne.useSuspenseQuery({ id: chatId, populate });
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(
    null,
  );

  const handleQuery = useTableQuery(trpcClient.documents.findAll.query, {
    populate: ['metadata'],
    where: {
      chat: chatId,
    },
  });

  const handleRow = useCallback(
    (_event: unknown, row: { original: DocumentType }) => {
      setSelectedDocument(row.original);
    },
    [],
  );

  const statistics = useMemo(() => {
    const total = Number(data.documentsCount);
    const inProgress = Number(data.inProgressDocumentsCount);
    const errored = Number(data.erroredDocumentsCount);
    const done = Number(data.doneDocumentsCount);

    return { total, inProgress, errored, done };
  }, [data]);

  return (
    <>
      <TablePage
        title={t('documents:texts.manage')}
        actions={<AddDocumentsModal chatId={chatId} />}
        breadcrumbs={[
          {
            label: t('globals:routeNames.chats'),
            to: '/chats',
          },
          {
            label: data.name,
            to: '/chats/$chatId/edit',
            params: { chatId },
          },
          {
            label: t('globals:routeNames.documents'),
            to: '/chats/$chatId/documents',
            params: { chatId },
          },
        ]}
        columns={columns}
        queryKey={[...getQueryKey(trpc.documents.findAll), { chatId }]}
        onQueryFn={handleQuery}
        onRow={handleRow}
      >
        <DocumentsStatusSummary {...statistics} />
      </TablePage>

      <DocumentContentModal
        documentId={selectedDocument?.id}
        onClose={() => setSelectedDocument(null)}
      />
    </>
  );
}
