import { cn } from '@utima/ui';
import { cva, type VariantProps } from 'class-variance-authority';
import type { LucideProps } from 'lucide-react';
import { memo, type ComponentPropsWithoutRef, type ElementType } from 'react';

export const iconWrapperStyles = cva(
  'inline-flex shrink-0 items-center justify-center rounded-lg',
  {
    variants: {
      size: {
        xs: 'size-7 text-xs',
        sm: 'size-8 text-xs',
        md: 'size-10 text-sm',
        lg: 'size-12 text-lg',
        xl: 'size-14 text-xl',
        '2xl': 'size-16 text-2xl',
        '3xl': 'size-20 text-3xl',
        '4xl': 'size-24 text-4xl',
        '5xl': 'size-28 text-5xl',
        '6xl': 'size-32 text-6xl',
        '7xl': 'size-36 text-6xl',
        '8xl': 'size-40 text-6xl',
        '9xl': 'size-44 text-7xl',
      },
      variant: {
        default: 'border border-border',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'default',
    },
  },
);

const iconSizes = {
  xs: 16,
  sm: 20,
  md: 22,
  lg: 24,
  xl: 28,
  '2xl': 32,
  '3xl': 38,
  '4xl': 42,
  '5xl': 44,
  '6xl': 48,
  '7xl': 52,
  '8xl': 58,
  '9xl': 64,
};

type IconWrapperProps = Omit<ComponentPropsWithoutRef<'span'>, 'size'> &
  VariantProps<typeof iconWrapperStyles> & {
    Icon: ElementType<LucideProps>;
    rounded?: boolean;
  };

export const IconWrapper = memo(function IconWrapper({
  size = 'md',
  className,
  Icon,
  variant,
  rounded,
  ...restProps
}: IconWrapperProps) {
  return (
    <span
      {...restProps}
      className={cn(
        iconWrapperStyles({ size, variant }),
        rounded && 'rounded-full',
        className,
      )}
    >
      <Icon size={iconSizes[size ?? 'md']} />
    </span>
  );
});
