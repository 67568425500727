import { Button, Dialog } from '@utima/ui';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  createDocumentUploadAdapter,
  FileUpload,
} from '@/components/fileUpload';
import { llamaAcceptFiles } from '@/components/fileUpload/llamaAcceptFiles';
import { trpc } from '@/services/trpc';

export type DocumentFormProps = {
  chatId: string;
};

export const UploadDocuments = memo(function DocumentForm({
  chatId,
}: DocumentFormProps) {
  const { t } = useTranslation(['glossary', 'documents']);
  const utils = trpc.useUtils();
  const uploadAdapter = createDocumentUploadAdapter(chatId, () =>
    utils.chats.findOne.invalidate({ id: chatId }),
  );

  return (
    <>
      <div className='min-h-0 grow'>
        <FileUpload
          uploadAdapter={uploadAdapter}
          accept={llamaAcceptFiles}
          maxSize={300 * 1024 * 1024} // 300MB
        />
      </div>

      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button variant='secondary'>{t('glossary:actions.close')}</Button>
        </Dialog.Close>
      </Dialog.Footer>
    </>
  );
});
