export const llamaAcceptFiles = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.dot'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/vnd.ms-word.document.macroEnabled.12': ['.docm'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': [
    '.dotx',
  ],
  'application/vnd.ms-word.template.macroEnabled.12': ['.dotm'],
  'application/rtf': ['.rtf'],
  'application/vnd.ms-works': ['.wps'],
  'application/x-wpd': ['.wpd'],
  'application/vnd.sun.xml.writer': ['.sxw'],
  'application/vnd.sun.xml.writer.template': ['.stw'],
  'application/vnd.sun.xml.writer.global': ['.sxg'],
  'application/x-iwork-pages-sffpages': ['.pages'],
  'application/x-macwrite': ['.mw', '.mcw'],
  'application/uoml+xml': ['.uot'],
  'application/uof': ['.uof', '.uos', '.uop'],
  'application/vnd.ms-powerpoint': ['.ppt', '.pot'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12': ['.pptm'],
  'application/vnd.openxmlformats-officedocument.presentationml.template': [
    '.potx',
  ],
  'application/vnd.ms-powerpoint.template.macroEnabled.12': ['.potm'],
  'application/x-iwork-keynote-sffkey': ['.key'],
  'application/vnd.oasis.opendocument.presentation': ['.odp'],
  'application/vnd.oasis.opendocument.graphics': ['.odg'],
  'application/vnd.oasis.opendocument.presentation-template': ['.otp'],
  'application/vnd.fdf': ['.fopd'],
  'application/vnd.sun.xml.impress': ['.sxi'],
  'application/vnd.sun.xml.impress.template': ['.sti'],
  'application/epub+zip': ['.epub'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/bmp': ['.bmp'],
  'image/svg+xml': ['.svg'],
  'image/tiff': ['.tiff'],
  'image/webp': ['.webp'],
  'text/html': ['.html', '.htm'],
  'text/markdown': ['.md', '.markdown'],
  'application/vnd.ms-excel': ['.xls', '.xlw', '.et', '.xlr'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12': ['.xlsb'],
  'text/csv': ['.csv'],
  'text/plain': ['.dif', '.sylk', '.slk', '.prn'],
  'application/x-iwork-numbers-sffnumbers': ['.numbers'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/vnd.oasis.opendocument.spreadsheet-template': ['.fods'],
  'application/x-dbf': ['.dbf'],
  'application/x-123': ['.wk1', '.wk2', '.wk3', '.wk4', '.wks'],
  'application/x-quattropro': ['.wq1', '.wq2', '.qpw'],
  'application/x-lotus-1-2-3': ['.wb1', '.wb2', '.wb3'],
  'application/octet-stream': ['.eth'],
  'text/tab-separated-values': ['.tsv'],
};
