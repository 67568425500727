import { Circle, CircleCheck, CircleDashed, CircleX } from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export type DocumentsStatusSummaryProps = {
  total: number;
  inProgress: number;
  errored: number;
  done: number;
};

export const DocumentsStatusSummary = memo(function DocumentsStatusSummary({
  total,
  inProgress,
  errored,
  done,
}: DocumentsStatusSummaryProps) {
  const { t } = useTranslation(['chats', 'glossary']);

  return (
    <div className='mb-4 rounded-radius border border-border bg-white p-4 text-sm'>
      <h4 className='mb-1 text-sm font-bold'>
        {t('chats:texts.documentsStatistics')}
      </h4>
      <div className='flex flex-wrap justify-between'>
        <div className='mt-2 flex items-center space-x-2'>
          <Circle className='size-4 text-info' />
          <span>
            {t('glossary:labels.total')}: <strong>{total}</strong>
          </span>
        </div>
        <div className='mt-2 flex items-center space-x-2'>
          <CircleDashed className='size-4 text-warning' />
          <span>
            {t('glossary:labels.processing')}: <strong>{inProgress}</strong>
          </span>
        </div>
        <div className='mt-2 flex items-center space-x-2'>
          <CircleX className='size-4 text-danger' />
          <span>
            {t('glossary:labels.errored')}: <strong>{errored}</strong>
          </span>
        </div>
        <div className='mt-2 flex items-center space-x-2'>
          <CircleCheck className='size-4 text-success' />
          <span>
            {t('glossary:labels.done')}:{' '}
            <strong>
              {done}/{total}
            </strong>
          </span>
        </div>
      </div>
    </div>
  );
});
