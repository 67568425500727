import { cn } from '@utima/ui';
import { motion } from 'framer-motion';
import pLimit from 'p-limit';
import { useMemo, useState } from 'react';
import {
  useDropzone,
  type DropzoneOptions,
  type DropzoneProps,
  type DropzoneState,
} from 'react-dropzone';
import { v7 as uuidV7 } from 'uuid';

import { DropZoneContent } from './components/DropZoneContent';
import { FileItem } from './components/FileItem';
import type { FileUploadAdapter, UploadingFile } from './fileUploadTypes';

export interface FileUploadProps extends Omit<DropzoneProps, 'children'> {
  containerClassName?: string;
  dropZoneClassName?: string;
  children?: (dropzone: DropzoneState) => React.ReactNode;
  showFilesList?: boolean;
  showErrorMessage?: boolean;
  uploadAdapter: FileUploadAdapter;
  maxConcurrentUploads?: number;
}

export function FileUpload({
  containerClassName,
  dropZoneClassName,
  children,
  showFilesList = true,
  showErrorMessage = true,
  uploadAdapter,
  maxConcurrentUploads = 5,
  ...restProps
}: FileUploadProps) {
  const [filesUploaded, setFilesUploaded] = useState<UploadingFile[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  const uploadLimiter = useMemo(
    () => pLimit(maxConcurrentUploads),
    [maxConcurrentUploads],
  );

  const uploadFile = async (file: File, index: number) => {
    try {
      await uploadLimiter(() => uploadAdapter.upload(file));

      setFilesUploaded(prev =>
        prev.map((item, i) =>
          i === index
            ? { ...item, status: 'completed' as const, progress: 100 }
            : item,
        ),
      );
    } catch (error) {
      setFilesUploaded(prev =>
        prev.map((file, i) =>
          i === index
            ? {
                ...file,
                status: 'error' as const,
                errorMessage:
                  error instanceof Error ? error.message : 'Upload failed',
              }
            : file,
        ),
      );
    }
  };

  const handleFileDrop: DropzoneOptions['onDrop'] = async (
    acceptedFiles,
    fileRejections,
    event,
  ) => {
    if (restProps.onDrop) {
      restProps.onDrop(acceptedFiles, fileRejections, event);
    } else {
      const newFiles = acceptedFiles.map(file => ({
        id: uuidV7(),
        file,
        status: 'uploading' as const,
        progress: 0,
      }));

      setFilesUploaded(prev => [...newFiles, ...prev]);

      newFiles.forEach((_, index) => {
        uploadFile(acceptedFiles[index], index);
      });

      if (fileRejections.length > 0) {
        let errorMsg = `Could not upload ${fileRejections[0].file.name}`;

        if (fileRejections.length > 1) {
          errorMsg =
            errorMsg + `, and ${fileRejections.length - 1} other files.`;
        }

        setErrorMessage(errorMsg);
      } else {
        setErrorMessage('');
      }
    }
  };

  const handleCloseUploadedFile = (index: number) => {
    setFilesUploaded(prev => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ]);
  };

  const deleteUploadedFile = async (index: number) => {
    const uploadedFile = filesUploaded[index];

    if (uploadAdapter.delete) {
      try {
        await uploadAdapter.delete(uploadedFile.file);
      } catch (error) {
        console.error('Failed to delete file:', error);

        return;
      }
    }

    handleCloseUploadedFile(index);
  };

  const dropzone = useDropzone({
    ...restProps,
    onDrop: handleFileDrop,
  });

  return (
    <div className={cn('flex flex-col gap-2 h-full', containerClassName)}>
      <div
        {...dropzone.getRootProps()}
        className={cn(
          'flex justify-center items-center w-full h-44 border-dashed border border-gray-200 rounded-lg hover:bg-accent hover:text-accent-foreground transition-all select-none cursor-pointer',
          dropZoneClassName,
        )}
      >
        <input {...dropzone.getInputProps()} />
        {children ? (
          children(dropzone)
        ) : (
          <DropZoneContent dropzone={dropzone} maxSize={restProps.maxSize} />
        )}
      </div>

      {showErrorMessage && errorMessage && (
        <span className='mt-3 text-xs text-red-600'>{errorMessage}</span>
      )}
      {showFilesList && (
        <motion.div
          className={cn('flex w-full flex-col gap-2 overflow-y-auto h-full')}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {filesUploaded.map((file, index) => (
            <motion.div
              key={file.id}
              layout
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
            >
              <FileItem
                file={file}
                onDelete={
                  uploadAdapter.delete && (() => deleteUploadedFile(index))
                }
                onClose={() => handleCloseUploadedFile(index)}
                autoCloseMs={2000}
              />
            </motion.div>
          ))}
        </motion.div>
      )}
    </div>
  );
}
