import { Button, cn, Dialog } from '@utima/ui';
import { File, FileSymlink, Globe } from 'lucide-react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddExternalDocument } from './AddExternalDocument';
import { AddWebsites } from './AddWebsites';
import { UploadDocuments } from './UploadDocuments';

export type AddDocumentsModalProps = {
  chatId: string;
};

export const AddDocumentsModal = memo(function AddDocumentsModal({
  chatId,
}: AddDocumentsModalProps) {
  const { t } = useTranslation(['glossary', 'documents', 'enums']);
  const [type, setType] = useState<'website' | 'file' | 'externalDocument'>(
    'website',
  );

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button>{t('glossary:actions.addResources')}</Button>
      </Dialog.Trigger>
      <Dialog.Content className='flex max-h-full min-h-[400px] w-[1000px] max-w-full flex-col'>
        <Dialog.Header>
          <Dialog.Title>{t('glossary:actions.addResources')}</Dialog.Title>
          <Dialog.Description>
            {t('documents:texts.addDescription')}
          </Dialog.Description>
        </Dialog.Header>

        <div className='flex gap-4'>
          <Button
            variant='ghost'
            outline
            className={cn(
              'py-4 flex grow flex-row items-center gap-2',
              type === 'website' && 'bg-gray-100 text-primary',
            )}
            onClick={() => setType('website')}
          >
            <Globe className='size-5' />
            <span>{t('enums:contentType.website')}</span>
          </Button>
          <Button
            variant='ghost'
            outline
            className={cn(
              'py-4 flex grow flex-row items-center gap-2',
              type === 'externalDocument' && 'bg-gray-100 text-primary',
            )}
            onClick={() => setType('externalDocument')}
          >
            <FileSymlink className='size-5' />
            <span>{t('enums:contentType.externalDocument')}</span>
          </Button>
          <Button
            variant='ghost'
            outline
            className={cn(
              'py-4 flex grow flex-row items-center gap-2',
              type === 'file' && 'bg-gray-100 text-primary',
            )}
            onClick={() => setType('file')}
          >
            <File className='size-5' />
            <span>{t('enums:contentType.document')}</span>
          </Button>
        </div>

        {type === 'website' && <AddWebsites chatId={chatId} />}
        {type === 'externalDocument' && <AddExternalDocument chatId={chatId} />}
        {type === 'file' && <UploadDocuments chatId={chatId} />}
      </Dialog.Content>
    </Dialog.Root>
  );
});
