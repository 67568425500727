import { renderMarkdown } from '@kanbu/shared';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import * as Tabs from '@radix-ui/react-tabs';
import { Dialog, Button } from '@utima/ui';
import { Loader2 } from 'lucide-react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { DisplayValue } from '@/components/displayValue/DisplayValue';
import { trpc } from '@/services/trpc';

export type DocumentContentModalProps = {
  documentId: string | null | undefined;
  onClose: () => void;
};

/**
 * Renders the content of a document in a modal.
 */
export const DocumentContentModal = memo(function DocumentContentModal({
  documentId,
  onClose,
}: DocumentContentModalProps) {
  const { t } = useTranslation(['glossary']);
  const [activeTab, setActiveTab] = useState('content');
  const { data: document, isLoading } = trpc.documents.findOne.useQuery(
    { id: documentId!, populate: ['content', 'metadata'] },
    { enabled: !!documentId },
  );
  const { data: embeddings } = trpc.documents.findAllEmbeddings.useQuery(
    { documentId: documentId! },
    { enabled: !!documentId },
  );

  const content = document?.content?.pages
    .map(page => page.markdown)
    .join('\n\n');

  return (
    <Dialog.Root open={!!documentId} onOpenChange={onClose}>
      <Dialog.Content className='flex h-[95vh] w-full max-w-[1200px] flex-col overflow-hidden'>
        <Dialog.Header>
          <Dialog.Title>{document?.name}</Dialog.Title>
        </Dialog.Header>

        {isLoading ? (
          <div className='flex min-h-40 items-center justify-center text-primary'>
            <Loader2 className='size-10 animate-spin' />
          </div>
        ) : (
          <Tabs.Root
            value={activeTab}
            onValueChange={setActiveTab}
            className='flex min-h-0 flex-1 flex-col'
          >
            <Tabs.List className='border-b border-border'>
              <div className='flex gap-2 px-4'>
                <Tabs.Trigger
                  value='content'
                  className='px-4 py-2 text-sm font-medium data-[state=active]:border-b-2 data-[state=active]:border-primary'
                >
                  Content
                </Tabs.Trigger>
                <Tabs.Trigger
                  value='metadata'
                  className='px-4 py-2 text-sm font-medium data-[state=active]:border-b-2 data-[state=active]:border-primary'
                >
                  Metadata
                </Tabs.Trigger>
                <Tabs.Trigger
                  value='embeddings'
                  className='px-4 py-2 text-sm font-medium data-[state=active]:border-b-2 data-[state=active]:border-primary'
                >
                  Embeddings
                </Tabs.Trigger>
              </div>
            </Tabs.List>

            <div className='min-h-0 flex-1'>
              <Tabs.Content value='content' className='h-full'>
                <ScrollArea.Root className='h-full'>
                  <ScrollArea.Viewport className='size-full p-4'>
                    <div className='markdown-wrapper whitespace-pre-wrap'>
                      {content ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: renderMarkdown(content),
                          }}
                        />
                      ) : (
                        <div>No content available</div>
                      )}
                    </div>
                  </ScrollArea.Viewport>
                  <ScrollArea.Scrollbar
                    className='flex touch-none select-none bg-gray-300 p-1 transition-colors hover:bg-gray-500'
                    orientation='vertical'
                  >
                    <ScrollArea.Thumb className='relative flex-1 rounded-full bg-gray-400' />
                  </ScrollArea.Scrollbar>
                </ScrollArea.Root>
              </Tabs.Content>

              <Tabs.Content value='metadata' className='h-full'>
                <ScrollArea.Root className='h-full'>
                  <ScrollArea.Viewport className='size-full p-4'>
                    <Card
                      title='Metadata'
                      className='mb-6 rounded-lg border border-border p-4'
                    >
                      <div className='space-y-4'>
                        {Object.entries(document?.metadata || {}).map(
                          ([key, value]) => (
                            <DisplayValue key={key} label={key} size='sm'>
                              {typeof value === 'string' ? (
                                value
                              ) : (
                                <pre className='rounded-lg bg-slate-100 p-2 text-xs'>
                                  {JSON.stringify(value, null, 2)}
                                </pre>
                              )}
                            </DisplayValue>
                          ),
                        )}
                        <DisplayValue label='language' size='sm'>
                          {document?.language}
                        </DisplayValue>
                      </div>
                    </Card>
                    <Card title='Content'>
                      <div className='w-full whitespace-pre-wrap rounded-lg font-sans text-sm leading-5'>
                        {content}
                      </div>
                    </Card>
                  </ScrollArea.Viewport>
                  <ScrollArea.Scrollbar
                    className='flex touch-none select-none bg-gray-300 p-1 transition-colors hover:bg-gray-500'
                    orientation='vertical'
                  >
                    <ScrollArea.Thumb className='relative flex-1 rounded-full bg-gray-400' />
                  </ScrollArea.Scrollbar>
                </ScrollArea.Root>
              </Tabs.Content>

              <Tabs.Content value='embeddings' className='h-full'>
                <ScrollArea.Root className='h-full'>
                  <ScrollArea.Viewport className='size-full p-4'>
                    <div className='rounded-lg border border-border p-4'>
                      <div className='space-y-4'>
                        {embeddings?.map((embedding, index) => (
                          <Card
                            title={`Chunk ${index + 1} (${embedding.uuid})`}
                            key={embedding.uuid}
                          >
                            <pre className='w-full whitespace-pre-wrap rounded-lg bg-slate-100 p-3 text-xs leading-5'>
                              {JSON.stringify(
                                JSON.parse(
                                  embedding.properties.metadata || '{}',
                                ),
                                null,
                                2,
                              )}
                            </pre>
                            <Card
                              title='keywordsContent'
                              className='w-full whitespace-pre-wrap rounded-lg font-sans text-sm leading-5'
                            >
                              {embedding.properties?.keywordsContent as string}
                            </Card>
                            <Card
                              title='embeddingContent'
                              className='w-full whitespace-pre-wrap rounded-lg font-sans text-sm leading-5'
                            >
                              {embedding.properties?.embeddingContent as string}
                            </Card>
                            <Card
                              title='cleanContent'
                              className='w-full whitespace-pre-wrap rounded-lg font-sans text-sm leading-5'
                            >
                              {/* @ts-expect-error - Ignore for now, will be remoed in the future */}
                              {embedding.properties?.cleanContent as string}
                            </Card>
                            <Card
                              title='content'
                              className='w-full whitespace-pre-wrap rounded-lg font-sans text-sm leading-5'
                            >
                              {embedding.properties?.content as string}
                            </Card>
                          </Card>
                        ))}
                      </div>
                    </div>
                  </ScrollArea.Viewport>
                  <ScrollArea.Scrollbar
                    className='flex touch-none select-none bg-gray-300 p-1 transition-colors hover:bg-gray-500'
                    orientation='vertical'
                  >
                    <ScrollArea.Thumb className='relative flex-1 rounded-full bg-gray-400' />
                  </ScrollArea.Scrollbar>
                </ScrollArea.Root>
              </Tabs.Content>
            </div>
          </Tabs.Root>
        )}

        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button variant='secondary'>{t('glossary:actions.close')}</Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
});
