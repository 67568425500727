import { Upload } from 'lucide-react';
import type { DropzoneState } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export interface DropZoneContentProps {
  dropzone: DropzoneState;
  maxSize?: number;
}

export function DropZoneContent({ dropzone, maxSize }: DropZoneContentProps) {
  const { t } = useTranslation(['globals']);

  if (dropzone.isDragAccept) {
    return (
      <div className='text-sm font-medium'>
        {t('globals:fileUpload.dropzone.dragActive')}
      </div>
    );
  }

  return (
    <div className='flex flex-col items-center gap-1.5'>
      <div className='flex flex-row items-center gap-0.5 text-sm font-medium'>
        <Upload className='mr-2 size-4' />
        {t('globals:fileUpload.dropzone.default')}
      </div>
      {maxSize && (
        <div className='text-xs font-medium text-gray-400'>
          {t('globals:fileUpload.dropzone.maxSize', {
            size: (maxSize / (1024 * 1024)).toFixed(2),
          })}
        </div>
      )}
    </div>
  );
}
