import type { ChatMessageType } from '@kanbu/schema';
import { ToolNames, type RagResultToolDef } from '@kanbu/schema/contracts';
import { ChatRole, ToolType } from '@kanbu/schema/enums';
import { DateFormat, formatUtils, renderMarkdown } from '@kanbu/shared';
import { cn } from '@utima/ui';
import { memo } from 'react';

import Feedback from '@/components/chat/Feedback.tsx';

export type MessageProps = {
  message: ChatMessageType;
};

function getMessageContent(message: ChatMessageType) {
  try {
    if (message?.content) {
      return message.content;
    }

    return message?.toolCalls
      ?.map(tool => {
        if (tool?.type === ToolType.Text) {
          return tool?.content;
        }

        if (tool?.name === ToolNames.RagResult) {
          return (
            tool?.args?.content +
            '\n\n' +
            tool?.args?.sources
              ?.map(
                (source: Required<RagResultToolDef>['sources'][number]) =>
                  `- [${source.fileName}](${source.sourceUrl})`,
              )
              .join('\n')
          );
        }

        // Generic tool call
        if ('content' in tool?.args) {
          const { content, ...restArgs } = tool?.args ?? {};

          return content + '\n\n' + JSON.stringify(restArgs, null, 2);
        }

        return '';
      })
      .join('\n\n');
  } catch {
    return 'Unknown error occurred while parsing message content';
  }
}

export const Message = memo(function Message({ message }: MessageProps) {
  const { role, createdAt, feedback } = message;
  const content = getMessageContent(message);

  return (
    <div className={cn('flex flex-col gap-1')}>
      <div
        className={cn(
          'flex max-w-[90%] rounded-2xl px-3.5 py-3 text-darkGray',
          {
            ['rounded-br-none ml-auto bg-secondary']: role === ChatRole.User,
            ['rounded-bl-none bg-gray-200']: role === ChatRole.Assistant,
          },
        )}
      >
        <div
          className='markdown-wrapper -mb-4 text-sm'
          dangerouslySetInnerHTML={{ __html: renderMarkdown(content) }}
        />
      </div>
      {feedback && <Feedback feedback={feedback} />}
      <div
        className={cn('text-xs mt-1', {
          ['text-right']: role === ChatRole.User,
          ['text-left']: role === ChatRole.Assistant,
        })}
      >
        {formatUtils.date(createdAt, DateFormat.DateTime)}
      </div>
    </div>
  );
});
